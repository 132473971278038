import React, { useEffect, useMemo, useState } from 'react';
import {
  Provider,
  setProvider,
  web3,
  Program,
} from '@project-serum/anchor';
import { Connection, PublicKey } from '@solana/web3.js';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { toast, ToastContainer } from 'react-toastify';

import { BalancesProvider } from './hooks';

import { deriveUser, CONFIG } from './utils';
import rewardPoolIdl from './idl/reward_pool.json';

import { Header } from './components/Header';
import { Pools } from './components/Pools';

import './App.css';

import 'react-toastify/dist/ReactToastify.css';

function App(): React.ReactElement {
  const [wallet, setWallet] = useState<PhantomWalletAdapter | null>(null);
  const [userAccount, setUserAccount] = useState<[PublicKey, number] | null>(null);
  const [rewardsPoolProgram, setRewardsPoolProgram] = useState<Program<any> | null>(null);

  const connection = useMemo(() => new Connection(CONFIG.HTTP_RPC_URL, 'confirmed'), []);
  const provider = useMemo(() => new Provider(connection, wallet as any, { commitment: 'confirmed' }), [connection, wallet]);

  useEffect(() => {
    setProvider(provider);

    const poolProgramId = new web3.PublicKey(CONFIG.REWARD_POOL_ID);
    const poolProgram = new Program(rewardPoolIdl as any, poolProgramId, provider);

    setRewardsPoolProgram(poolProgram);
  }, [provider]);

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      const derivedUserInfo = await deriveUser(
        new web3.PublicKey(CONFIG.REWARD_POOL_ID),
        wallet?.publicKey as PublicKey,
        new web3.PublicKey(CONFIG.KKO_POOL),
      );

      setUserAccount(derivedUserInfo);
    };

    if (wallet) {
      getUser();
    }
  }, [wallet]);

  const connectToPhantom = async (): Promise<void> => {
    try {
      const phantomWallet = new PhantomWalletAdapter();

      phantomWallet.on('connect', () => {
        if (phantomWallet.publicKey) {
          setWallet(phantomWallet);

          toast.success('Connected to Phantom');
        }
      });

      phantomWallet.connect();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <BalancesProvider>
      <>
        <Header
          wallet={wallet}
          connectToPhantom={connectToPhantom}
        />

        <Pools
          wallet={wallet}
          connection={connection}
          userAccount={userAccount}
          rewardsPoolProgram={rewardsPoolProgram}
          connectToPhantom={connectToPhantom}
        />

        <ToastContainer
          position="bottom-right"
          autoClose={10000}
          hideProgressBar
          newestOnTop
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    </BalancesProvider>
  );
}

export default App;
