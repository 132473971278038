import { useState, useEffect } from 'react';

interface useFetchReturn {
  data: any
  loading: boolean | null
  error: string | null | unknown
}

export const useFetch = (url: string, options?: Record<string, unknown>): useFetchReturn => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean | null>(false);
  const [error, setError] = useState<string | null | unknown>(null);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      try {
        const res = await fetch(url, options);
        const json = await res.json();

        if (json.data) {
          setData(json.data);
        } else {
          setData(json);
        }
        setLoading(false);
      } catch (err) {
        setError(err);
      }
    };

    fetchData();
  }, [options, url]);

  return { data, loading, error };
};
