import { web3 } from '@project-serum/anchor';
import { AccountLayout, u64 } from '@solana/spl-token';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { Connection, PublicKey } from '@solana/web3.js';

import { CONFIG } from '.';

export const truncateString = (text: string, startChars: number, endChars: number, maxLength: number): string => {
  if (text.length > maxLength) {
    const start = text.substring(0, startChars);
    const end = text.substring(text.length - endChars, text.length);

    return `${start}...${end}`;
  }
  return text;
};

export const formatNumber = (number: number, decimals = 2): number | string => {
  if (decimals === 2 && number === 0) return '0.00';

  const parsedNumber = Number(number).toLocaleString('en-us', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });

  return parsedNumber;
};

export const formatBigNumber = (number: string, format = false): number | string => {
  const parsedNumber = parseFloat(number);

  if (Math.abs(parsedNumber) >= 10 ** 12) {
    return `${format ? formatNumber(parsedNumber / 10 ** 12) : Math.round(parsedNumber / 10 ** 12)}T`;
  }

  if (Math.abs(parsedNumber) >= 10 ** 9) {
    return `${format ? formatNumber(parsedNumber / 10 ** 9) : Math.round(parsedNumber / 10 ** 9)}B`;
  }

  if (Math.abs(parsedNumber) >= 10 ** 6) {
    return `${format ? formatNumber(parsedNumber / 10 ** 6) : Math.round(parsedNumber / 10 ** 6)}M`;
  }

  if (Math.abs(parsedNumber) >= 10 ** 3) {
    return `${format ? formatNumber(parsedNumber / 10 ** 3) : Math.round(parsedNumber / 10 ** 3)}K`;
  }

  return (format ? formatNumber(parseFloat(number)) : parseFloat(number));
};

export const deriveUser = (
  programId: web3.PublicKey,
  owner: web3.PublicKey,
  pool: web3.PublicKey,
): Promise<[web3.PublicKey, number]> => {
  return web3.PublicKey.findProgramAddress(
    [
      owner.toBuffer(),
      pool.toBuffer(),
    ],
    programId,
  );
};

export const derivePoolSigner = (
  programId: web3.PublicKey,
  pool: web3.PublicKey,
): Promise<[web3.PublicKey, number]> => {
  return web3.PublicKey.findProgramAddress(
    [
      pool.toBuffer(),
    ],
    programId,
  );
};

const sendSignedTransaction = async (connection: Connection, signedTransaction: any): Promise<string> => {
  const rawTransaction = signedTransaction.serialize();

  const txid = await connection.sendRawTransaction(rawTransaction, {
    skipPreflight: true,
    preflightCommitment: 'confirmed',
  });

  return txid;
};

export const sendTransaction = async (
  connection: Connection,
  wallet: PhantomWalletAdapter,
  transaction: any,
): Promise<string> => {
  transaction.recentBlockhash = (await connection.getRecentBlockhash('confirmed')).blockhash;
  transaction.setSigners(wallet.publicKey);

  const signedTransaction = await wallet.signTransaction(transaction);

  const txid = await sendSignedTransaction(connection, signedTransaction);

  return txid;
};

export const getUserTokenBalance = async (
  connection: Connection,
  publicKey: PublicKey,
): Promise<{ mint: string; amount: string }[]> => {
  const tokenAcc = await connection.getTokenAccountsByOwner(
    publicKey,
    { mint: new web3.PublicKey(CONFIG.KKO_TOKEN) },
  )
    .then(({ value }) => {
      return value.map((acc) => {
        const accountInfo = AccountLayout.decode(acc.account.data);

        return {
          mint: new web3.PublicKey(accountInfo.mint).toString(),
          amount: u64.fromBuffer(accountInfo.amount).toString(),
        };
      });
    });

  return tokenAcc;
};

export const getKkoBalance = async (
  connection: Connection,
  publicKey: PublicKey,
): Promise<string> => {
  try {
    const balances = await getUserTokenBalance(connection, publicKey);
    const kkoBalance = balances.find(({ mint }) => mint === CONFIG.KKO_TOKEN);

    if (kkoBalance) {
      return kkoBalance.amount;
    }

    return '0';
  } catch (err) {
    console.log(err);

    return '0';
  }
};
