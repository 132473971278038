import React from 'react';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';

import { truncateString } from '../../utils';

import logo from '../../assets/logo-old.svg';

import './style.scss';

interface HeaderProps {
  wallet: PhantomWalletAdapter | null
  connectToPhantom: () => void
}

export const Header = ({ wallet, connectToPhantom }: HeaderProps): React.ReactElement => {
  return (
    <div className="header">
      <div className="logo">
        <img
          src={logo}
          alt="logo"
        />
      </div>

      <div className="switcher">
        <a
          href="/"
          className="sol active"
        >
          SOL
        </a>
        <a
          href="/ethereum"
          className="eth"
        >
          ETH
        </a>
      </div>

      <div className="menu">
        <a href="https://kineko.io">Protocol</a>
        <a
          target="_blank"
          href="https://migrate.kineko.io/"
          rel="noreferrer"
        >
          Migrate
          <span>↗</span>
        </a>
        <a
          target="_blank"
          href="https://raydium.io/swap/?from=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&to=kiNeKo77w1WBEzFFCXrTDRWGRWGP8yHvKC9rX6dqjQh"
          rel="noreferrer"
        >
          Trade
          <span>↗</span>
        </a>
      </div>

      <div className="controls">
        {/* <div className="claim">KKO</div> */}
        {wallet ? (
          <div className="user-account">
            {wallet.publicKey && truncateString(wallet.publicKey?.toString(), 6, 4, 10)}
          </div>
        ) : (
          <div
            className="primary connect-wallet"
            onClick={connectToPhantom}
          >
            Connect wallet
          </div>
        )}
      </div>
    </div>
  );
};
