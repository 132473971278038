export const CONFIG = {
  HTTP_RPC_URL: 'https://solana--mainnet.datahub.figment.io/apikey/d19a8f40a14dcd9643319e93c7702335',
  KKO_POOL: '3UKe8FrMQx3RXtrLKqfScbZB8wzjMwrnGSj5Wq3p6F47',
  KKO_TOKEN: '7xr1Doc1NiMWbUg99YVFqQSLfYXNzo6YvacXUsSgBMNW',
  KKO_POOL_SIGNER: 'CHrXy18yzjedMptcr8GUi97xjrizaUCJfS3Tqhi1Miw3',
  STAKING_MINT: '7xr1Doc1NiMWbUg99YVFqQSLfYXNzo6YvacXUsSgBMNW',
  STAKING_VAULT: 'G8pGFQWsevxp9TYvM85KcG5C1vTzm1F5UEhLxc6S1Av4',
  REWARD_A_MINT: 'kiNeKo77w1WBEzFFCXrTDRWGRWGP8yHvKC9rX6dqjQh',
  REWARD_A_VAULT: 'LyEwhKBAAacV4PC4fWypqkMHmGLWfFYPNv6rWxTQ1Za',
  REWARD_B_MINT: 'kiNeKo77w1WBEzFFCXrTDRWGRWGP8yHvKC9rX6dqjQh',
  REWARD_B_VAULT: 'LyEwhKBAAacV4PC4fWypqkMHmGLWfFYPNv6rWxTQ1Za',
  REWARD_POOL_ID: 'CzMjHr8pAc8haq94NvfkurfLs61UPks3jaFcEqvYM3Mr',
  TOKEN_PROGRAM_ID: 'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA',
};

// export const DEVNET_CONFIG = {
//   HTTP_RPC_URL: 'https://solana--devnet.datahub.figment.io/apikey/b4a29ff44c323b703645021a2ca61400',
//   KKO_POOL: 'CDfUdHpDKzzyrCeWRY3YxuZ6c5rvThKGehgB4ZPkusMY',
//   KKO_TOKEN: 'BuRuFrnaVrFCJmdmduPSPf1LQwWfkmV8rg1zVaMnHjMd',
//   STAKING_MINT: 'BuRuFrnaVrFCJmdmduPSPf1LQwWfkmV8rg1zVaMnHjMd',
//   STAKING_VAULT: 'DPq7b4gLUL6qFf6xzkLpCoyUrTWd9zX5Ysibe4n63GmM',
//   REWARD_A_MINT: '2MAEn9tj1jiDn7rA7pVemCFeq9FfLMTC5EKbwLG9jXd1',
//   REWARD_A_VAULT: 'AkAgAgteeYTnGPu7HRGYn6PGf6dAekkswDWWhejaZe58',
//   REWARD_B_MINT: '2MAEn9tj1jiDn7rA7pVemCFeq9FfLMTC5EKbwLG9jXd1',
//   REWARD_B_VAULT: 'AkAgAgteeYTnGPu7HRGYn6PGf6dAekkswDWWhejaZe58',
//   REWARD_POOL_ID: '2qdEzkVWZptLzZcYPY5411v3VADVVUAQvua5TFkz1UHv',
//   TOKEN_PROGRAM_ID: 'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA',
// };
