import React, { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';

import './style.scss';

interface StakeTabSwitcherProps {
  action: string
  setAction: Dispatch<SetStateAction<string>>
}

const StakeTabSwitcher = ({ action, setAction }: StakeTabSwitcherProps): React.ReactElement => {
  return (
    <div className="tab-switcher">
      <div
        className={classNames({ selected: action === 'stake' })}
        onClick={() => setAction('stake')}
      >
        Stake
      </div>

      <div
        className={classNames({ selected: action === 'unstake' })}
        onClick={() => setAction('unstake')}
      >
        Unstake
      </div>
    </div>
  );
};

export default StakeTabSwitcher;
