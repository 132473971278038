import React, { useEffect, useState } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { Program, web3 } from '@project-serum/anchor';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { OpenOrders } from '@project-serum/serum';

import { useBalances, useFetch } from '../../hooks';
import { BalancesContextType } from '../../hooks/context/balances';
import {
  CONFIG,
  KKO_DECIMALS,
  getKkoBalance,
  formatBigNumber,
} from '../../utils';

import StakeModal from '../StakeModal';

import './style.scss';
import { AMM_INFO_LAYOUT_V4, MINT_LAYOUT } from './constants';

interface PoolsProps {
  wallet: PhantomWalletAdapter | null
  connection: Connection | null
  userAccount: [PublicKey, number] | null
  rewardsPoolProgram: Program<any> | null
  connectToPhantom: () => void
}

export const Pools = ({
  wallet,
  connection,
  userAccount,
  rewardsPoolProgram,
  connectToPhantom,
}: PoolsProps): React.ReactElement => {
  const { userKkoBalance, setUserKkoBalance } = useBalances() as BalancesContextType;

  const [show, setShow] = useState(false);
  const [action, setAction] = useState('stake');
  const [poolName, setPoolName] = useState('');
  const [kkoPoolTvl, setKkoPoolTvl] = useState<string>('0');
  const [kkoPoolInfo, setKkoPoolInfo] = useState<any | null>(null);
  const [lpValue, setLpValue] = useState(0);

  const { data } = useFetch('https://api.coingecko.com/api/v3/simple/price?ids=kineko&vs_currencies=usd');

  const kkoPoolTvlInUsd = ((parseFloat(kkoPoolTvl) / KKO_DECIMALS) * lpValue).toFixed(2);
  const rewardUSDValue = kkoPoolInfo
      && (parseFloat(kkoPoolInfo.rewardARate) * 365 * 86400 * data.kineko.usd) / KKO_DECIMALS;
  const kkoPoolApr = kkoPoolInfo
      && ((rewardUSDValue) / parseFloat(kkoPoolTvlInUsd)) * 100;

  useEffect(() => {
    const getUserKkoBalance = async (): Promise<void> => {
      const balance = await getKkoBalance(connection as Connection, wallet?.publicKey as PublicKey);

      setUserKkoBalance(balance);
    };

    if (connection && wallet) {
      getUserKkoBalance();
    }
  }, [connection, setUserKkoBalance, wallet]);

  useEffect(() => {
    const getPoolTvl = async (): Promise<void> => {
      const balance = await getKkoBalance(connection as Connection, new web3.PublicKey(CONFIG.KKO_POOL_SIGNER));

      setKkoPoolTvl(balance);
    };

    if (connection) {
      getPoolTvl();
    }
  }, [connection]);

  useEffect(() => {
    const getPoolRewardRate = async (): Promise<void> => {
      try {
        const kkoPoolAccountInfo = await connection?.getAccountInfo(new web3.PublicKey(CONFIG.KKO_POOL));
        const poolInfo = rewardsPoolProgram?.coder.accounts.decode('Pool', Buffer.from(kkoPoolAccountInfo?.data as any));

        if (poolInfo) {
          setKkoPoolInfo(poolInfo);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (kkoPoolTvl) {
      getPoolRewardRate();
    }
  }, [rewardsPoolProgram?.coder.accounts, connection, kkoPoolTvl]);

  const handlePoolClick = (name: string): void => {
    setPoolName(name);
    setShow(true);
  };

  useEffect(() => {
    const getVaultData = async (vaultAddress: string, INSTRUCTIONS: any): Promise<any> => {
      try {
        const vaultPublicKey = new web3.PublicKey(vaultAddress);

        const vaultAddressResult = await connection?.getAccountInfo(vaultPublicKey);

        const vaultData = INSTRUCTIONS.decode(Buffer.from(vaultAddressResult?.data as any, 'base64'));

        return vaultData;
      } catch (error) {
        console.log(error);

        return null;
      }
    };

    const getPoolStatus = async (
      lpMintAddress: string,
      poolCoinTokenaccount: string,
      poolPcTokenaccount: string,
    ): Promise<any> => {
      let result;
      result = await connection?.getAccountInfo(new web3.PublicKey(lpMintAddress));

      const mintData = MINT_LAYOUT.decode(Buffer.from(result?.data as any, 'base64'));

      const lpTotalSupply = mintData.supply.toString();
      result = await connection?.getTokenAccountBalance(new web3.PublicKey(poolCoinTokenaccount));
      const RayBalance = result?.value.amount;

      result = await connection?.getTokenAccountBalance(new web3.PublicKey(poolPcTokenaccount));
      const UsdtBalance = result?.value.amount;

      return {
        totalSupply: lpTotalSupply,
        coinBalance: RayBalance,
        pcBalance: UsdtBalance,
      };
    };

    const getPrice = async (): Promise<void> => {
      const poolPosition = await getPoolStatus(
        '7xr1Doc1NiMWbUg99YVFqQSLfYXNzo6YvacXUsSgBMNW',
        '9RyM5aXG1wcRmiGYLRTas3gySyoPvFCRcetTQGrVbPQ4',
        'H19PNJ24H3zMheS2wyGzNoN29dWZ2JxrQgBmqSeFJmb7',
      );

      const pcBalance = Number(poolPosition.pcBalance);
      // const coinBalance = Number(poolPosition.coinBalance);
      const totalSupply = Number(poolPosition.totalSupply);

      /**
       * To get AMM ID and fetch circulating values.
       */

      const getAMMData = await getVaultData('FvN7dJz7GX1XB1BTk6jD5rEKRxQc3ZwNkWJKai5sBJWS', AMM_INFO_LAYOUT_V4);
      // const needTakePnlCoin = Number(getAMMData.needTakePnlCoin.toString());
      const needTakePnlPc = Number(getAMMData.needTakePnlPc.toString());

      /**
       * Get and decode AMM Open Order values
       */
      const OPEN_ORDER_INSTRUCTIONS = OpenOrders.getLayout(new web3.PublicKey('Hh87TCaD5syc3ssin4kt9gdmoMwqYbQRC4ABw5bvD6vo'));
      const openOrdersData = await getVaultData('Hh87TCaD5syc3ssin4kt9gdmoMwqYbQRC4ABw5bvD6vo', OPEN_ORDER_INSTRUCTIONS);

      // const baseTokenTotal = Number(openOrdersData.baseTokenTotal.toString());
      const quoteTokenTotal = Number(openOrdersData.quoteTokenTotal.toString());

      // const r0Bal = (coinBalance
      //   + baseTokenTotal
      //   - needTakePnlCoin
      // ) / 10 ** 6;

      const r1Bal = (pcBalance
        + quoteTokenTotal
        - needTakePnlPc
      ) / 10 ** 6;

      const poolTVL = r1Bal * 2;
      const unitLpValue = poolTVL / (totalSupply / KKO_DECIMALS);

      setLpValue(unitLpValue);
    };

    getPrice();
  }, [connection]);

  return (
    <>
      <div className="content-box">
        <div className="title">Staking Pools</div>

        <div className="item-selector">
          <div
            className="item"
            onClick={() => {
              if (!wallet) {
                connectToPhantom();
              } else {
                handlePoolClick('KKO-USDC');
              }
            }}
          >
            <div>
              <div className="label">KKO-USDC</div>
              <div className="subline">
                APR:
                {' '}
                {Number.isNaN(kkoPoolApr) ? 0 : kkoPoolApr?.toFixed(2)}
                %
              </div>
              <div className="tvl">
                $
                {kkoPoolTvlInUsd && !Number.isNaN(kkoPoolTvlInUsd)
                  ? formatBigNumber(parseFloat(kkoPoolTvlInUsd).toFixed(2), true)
                  : 0}
              </div>
            </div>
          </div>

        </div>
      </div>

      <StakeModal
        show={show}
        setShow={setShow}
        poolName={poolName}
        action={action}
        setAction={setAction}
        wallet={wallet}
        rewardsPoolProgram={rewardsPoolProgram}
        userAccount={userAccount as any}
        connection={connection as Connection}
        kkoBalance={userKkoBalance as string}
        kkoPoolInfo={kkoPoolInfo}
        kkoPoolTvl={kkoPoolTvl}
      />
    </>
  );
};
