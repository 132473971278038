import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import * as serumAssoToken from '@project-serum/associated-token';
import { Connection, PublicKey } from '@solana/web3.js';
import { web3, BN } from '@project-serum/anchor';
import { toast } from 'react-toastify';
import BigNumber from 'bignumber.js';

import { useBalances, useOnClickOutside } from '../../hooks';
import { BalancesContextType } from '../../hooks/context/balances';
import {
  derivePoolSigner,
  TOKEN_PROGRAM_ID,
  CONFIG,
  KKO_DECIMALS,
  sendTransaction,
  getKkoBalance,
} from '../../utils';

import StakeForm from './StakeForm';
import StakeTabSwitcher from './StakeTabSwitcher';

import './style.scss';

interface ModalProps {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  poolName: string
  action: string
  setAction: Dispatch<SetStateAction<string>>
  wallet: any
  rewardsPoolProgram: any
  userAccount: [web3.PublicKey, number]
  connection: Connection
  kkoBalance: string
  kkoPoolInfo: any
  kkoPoolTvl: string
}

const Modal = ({
  show,
  setShow,
  poolName,
  action,
  setAction,
  wallet,
  rewardsPoolProgram,
  userAccount,
  connection,
  kkoBalance,
  kkoPoolInfo,
  kkoPoolTvl,
}: ModalProps): React.ReactElement => {
  const {
    userKkoBalance,
    setUserKkoBalance,
    userStakedBalance,
    setUserStakedBalance,
    userClaimableTokens,
    setUserClaimableTokens,
  } = useBalances() as BalancesContextType;

  const modalRef = useRef(null);

  const [amount, setAmount] = useState('0');
  const [kkoStakeInfo, setKkoStakeUserInfo] = useState<any | null>(null);

  const closeModal = (): void => {
    setShow(false);
  };

  useOnClickOutside(modalRef, closeModal);

  useEffect(() => {
    if (rewardsPoolProgram && kkoStakeInfo) {
      const decodedUserAccountInfo = rewardsPoolProgram.coder.accounts.decode('User', kkoStakeInfo?.data);
      const { balanceStaked, rewardAPerTokenComplete, rewardAPerTokenPending } = decodedUserAccountInfo;
      const { rewardAPerTokenStored, rewardARate } = kkoPoolInfo;

      setUserStakedBalance(balanceStaked);

      const timeDiff = new BigNumber(Date.now() / 1000).minus(new BigNumber(kkoPoolInfo?.lastUpdateTime.toString()));
      const recalculatedReward = new BigNumber(rewardAPerTokenStored.toString())
        .plus(timeDiff.times(new BigNumber(rewardARate.toString()))
          .times(new BigNumber('18446744073709551615')).div(new BigNumber(kkoPoolTvl)));
      const claimableTokens = (new BigNumber(balanceStaked.toString()).times(recalculatedReward.minus(rewardAPerTokenComplete)).div(new BigNumber('18446744073709551615'))).plus(rewardAPerTokenPending);

      if (claimableTokens.div(new BigNumber(KKO_DECIMALS)).gte(new BigNumber(1 / 10 ** 6))) {
        setUserClaimableTokens(claimableTokens);
      } else {
        setUserClaimableTokens(new BigNumber(0));
      }
    }
  }, [kkoPoolInfo, kkoPoolTvl, kkoStakeInfo, rewardsPoolProgram, setUserClaimableTokens, setUserStakedBalance]);

  useEffect(() => {
    const getKKoStakeUserInfo = async (): Promise<void> => {
      const kkoStakeUserInfo = await connection?.getAccountInfo(userAccount[0]);

      setKkoStakeUserInfo(kkoStakeUserInfo);
    };

    if (userAccount) {
      getKKoStakeUserInfo();
    }
  }, [connection, userAccount]);

  useEffect(() => {
    const getUserStakedBalance = async (): Promise<void> => {
      const kkoStakeUserInfo = await connection?.getAccountInfo(userAccount[0]);

      if (kkoStakeUserInfo) {
        const decodedUserAccountInfo = rewardsPoolProgram.coder.accounts.decode('User', kkoStakeUserInfo?.data);
        const { balanceStaked } = decodedUserAccountInfo;

        setUserStakedBalance(balanceStaked);
      }
    };

    if (connection && rewardsPoolProgram?.coder?.accounts && userAccount) {
      getUserStakedBalance();
    }
  }, [connection, rewardsPoolProgram?.coder?.accounts, setUserStakedBalance, userAccount, action]);

  useEffect(() => {
    const getUserKkoBalance = async (): Promise<void> => {
      const balance = await getKkoBalance(connection as Connection, wallet?.publicKey as PublicKey);

      setUserKkoBalance(balance);
    };

    if (connection && wallet) {
      getUserKkoBalance();
    }
  }, [connection, setUserKkoBalance, wallet, action]);

  const stake = async (): Promise<void> => {
    try {
      const txn = new web3.Transaction();

      if (!kkoStakeInfo) {
        txn.add(
          rewardsPoolProgram.instruction.createUser(
            new BN(userAccount[1]), {
              accounts: {
                pool: new web3.PublicKey(CONFIG.KKO_POOL),
                user: userAccount[0],
                owner: wallet?.publicKey,
                systemProgram: new web3.PublicKey('11111111111111111111111111111111'),
              },
            },
          ),
        );
      }

      const kkoStakingMint = await serumAssoToken.getAssociatedTokenAddress(
        wallet?.publicKey,
        new web3.PublicKey(CONFIG.STAKING_MINT),
      );

      const [poolSigner] = await derivePoolSigner(
        new web3.PublicKey(CONFIG.REWARD_POOL_ID),
        new web3.PublicKey(CONFIG.KKO_POOL),
      );

      txn.add(
        rewardsPoolProgram.instruction.stake(
          new BN(parseFloat(amount) * KKO_DECIMALS),
          {
            accounts: {
              pool: new web3.PublicKey(CONFIG.KKO_POOL),
              stakingVault: new web3.PublicKey(CONFIG.STAKING_VAULT),
              user: userAccount[0],
              owner: wallet?.publicKey,
              stakeFromAccount: kkoStakingMint,
              poolSigner,
              tokenProgram: TOKEN_PROGRAM_ID,
            },
          },
        ),
      );

      const txid = await sendTransaction(connection, wallet, txn);

      const balanceInterval = setInterval(async () => {
        const balance = await getKkoBalance(connection as Connection, wallet?.publicKey as PublicKey);

        if (userKkoBalance !== balance) {
          setUserKkoBalance(balance);
          setAmount('0');
          clearInterval(balanceInterval);
        }
      }, 500);

      toast.success(() => (
        <div>
          <div>Transaction sent!</div>
          <a
            style={{ color: '#ebebeb', fontSize: '0.875rem', textDecoration: 'underline' }}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://explorer.solana.com/tx/${txid}?cluster=devnet`}
          >
            See it on the Explorer
          </a>
        </div>
      ));

      // closeModal();
    } catch (err) {
      console.log(err);

      toast.error(`Error: ${err}`);
    }
  };

  const unstake = async (): Promise<void> => {
    try {
      const txn = new web3.Transaction();

      const kkoStakingMint = await serumAssoToken.getAssociatedTokenAddress(
        wallet?.publicKey,
        new web3.PublicKey(CONFIG.STAKING_MINT),
      );

      const [poolSigner] = await derivePoolSigner(
        new web3.PublicKey(CONFIG.REWARD_POOL_ID),
        new web3.PublicKey(CONFIG.KKO_POOL),
      );

      txn.add(
        rewardsPoolProgram.instruction.unstake(
          new BN(parseFloat(amount) * KKO_DECIMALS),
          {
            accounts: {
              pool: new web3.PublicKey(CONFIG.KKO_POOL),
              stakingVault: new web3.PublicKey(CONFIG.STAKING_VAULT),
              user: userAccount[0],
              owner: wallet?.publicKey,
              stakeFromAccount: kkoStakingMint,
              poolSigner,
              tokenProgram: TOKEN_PROGRAM_ID,
            },
          },
        ),
      );

      const txid = await sendTransaction(connection, wallet, txn);

      const balanceInterval = setInterval(async () => {
        const kkoStakeUserInfo = await connection?.getAccountInfo(userAccount[0]);
        const decodedUserAccountInfo = rewardsPoolProgram.coder.accounts.decode('User', kkoStakeUserInfo?.data);
        const { balanceStaked } = decodedUserAccountInfo;

        if (!balanceStaked.eq(userStakedBalance)) {
          setUserStakedBalance(balanceStaked);
          setAmount('0');
          clearInterval(balanceInterval);
        }
      }, 500);

      toast.success(() => (
        <div>
          <div>Transaction sent!</div>
          <a
            style={{ color: '#ebebeb', fontSize: '0.875rem', textDecoration: 'underline' }}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://explorer.solana.com/tx/${txid}?cluster=devnet`}
          >
            See it on the Explorer
          </a>
        </div>
      ));
    } catch (err) {
      console.log(err);

      toast.error(`Error: ${err}`);
    }
  };

  const claim = async (): Promise<void> => {
    try {
      const txn = new web3.Transaction();

      const rewardAAccount = await serumAssoToken.getAssociatedTokenAddress(
        wallet?.publicKey,
        new web3.PublicKey(CONFIG.REWARD_A_MINT),
      );

      const rewardBAccount = await serumAssoToken.getAssociatedTokenAddress(
        wallet?.publicKey,
        new web3.PublicKey(CONFIG.REWARD_B_MINT),
      );

      const rewardAAccountInfo = await connection?.getAccountInfo(rewardAAccount);

      if (!rewardAAccountInfo) {
        txn.add(
          await serumAssoToken.createAssociatedTokenAccount(
            // who will pay for the account creation
            wallet.publicKey,
            // who is the account getting created for
            wallet.publicKey,
            // what mint address token is being created
            new web3.PublicKey(CONFIG.REWARD_A_MINT),
          ),
        );
      }

      const [poolSigner] = await derivePoolSigner(
        new web3.PublicKey(CONFIG.REWARD_POOL_ID),
        new web3.PublicKey(CONFIG.KKO_POOL),
      );

      txn.add(
        rewardsPoolProgram.instruction.claim(
          {
            accounts: {
              pool: new web3.PublicKey(CONFIG.KKO_POOL),
              stakingVault: new web3.PublicKey(CONFIG.STAKING_VAULT),
              user: userAccount[0],
              owner: wallet?.publicKey,
              poolSigner,
              tokenProgram: TOKEN_PROGRAM_ID,
              rewardAVault: new web3.PublicKey(CONFIG.REWARD_A_VAULT),
              rewardBVault: new web3.PublicKey(CONFIG.REWARD_B_VAULT),
              rewardAAccount,
              rewardBAccount,
            },
          },
        ),
      );

      const txid = await sendTransaction(connection, wallet, txn);

      setUserClaimableTokens(new BigNumber(0));

      toast.success(() => (
        <div>
          <div>Transaction sent!</div>
          <a
            style={{ color: '#ebebeb', fontSize: '0.875rem', textDecoration: 'underline' }}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://explorer.solana.com/tx/${txid}?cluster=devnet`}
          >
            See it on the Explorer
          </a>
        </div>
      ));
    } catch (err) {
      toast.error(`Error: ${err}`);

      console.log(err);
    }
  };

  const modalBody = show && (
    <div className="modal">
      <div ref={modalRef}>
        <div className="modal-head">
          <div className="title">
            Stake
            {' '}
            {poolName}
          </div>
        </div>

        <div className="modal-content">
          <StakeTabSwitcher
            action={action}
            setAction={setAction}
          />

          <StakeForm
            amount={amount}
            action={action}
            stake={stake}
            unstake={unstake}
            claim={claim}
            setStakeAmount={setAmount}
            kkoBalance={kkoBalance}
            userClaimableTokens={userClaimableTokens}
            userStakedBalance={userStakedBalance}
          />
        </div>

        <div
          className="close"
          onClick={closeModal}
        />
      </div>
    </div>
  );

  return createPortal(modalBody, document.body);
};

export default Modal;
