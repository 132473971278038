import React, { Dispatch, SetStateAction } from 'react';
import { capitalize } from 'lodash';
import BigNumber from 'bignumber.js';

import { KKO_DECIMALS } from '../../../utils';

import './style.scss';

interface StakeFormProps {
  amount: string
  action: string
  kkoBalance: string
  stake: () => void
  unstake: () => void
  claim: () => void
  setStakeAmount: Dispatch<SetStateAction<string>>
  userClaimableTokens: BigNumber
  userStakedBalance: BigNumber
}

const StakeForm = ({
  amount,
  action,
  kkoBalance,
  stake,
  unstake,
  claim,
  setStakeAmount,
  userClaimableTokens,
  userStakedBalance,
}: StakeFormProps): React.ReactElement => {
  const availableBalance = action === 'stake' ? kkoBalance : userStakedBalance.toString();

  const handlePercentAmountClick = (percent: string): void => {
    const percentAmount = parseFloat(availableBalance) * (parseFloat(percent) / 100);

    setStakeAmount((percentAmount / KKO_DECIMALS).toString());
  };

  return (
    <div className="form">
      <div className="row">
        <div className="control">
          <input
            value={amount}
            onChange={(e) => setStakeAmount(e.target.value)}
            className="input amount"
            type="input"
            placeholder="0.00"
          />

          <div
            className="button small stake-button"
            onClick={action === 'stake' ? stake : unstake}
          >
            {capitalize(action)}
          </div>
        </div>

        <div className="control small">
          <span>
            Available:
            {' '}
            <span className="available-amount">
              {parseFloat(availableBalance) / KKO_DECIMALS}
              {' '}
              KKO-USDC
            </span>
          </span>
        </div>

        <div className="control">
          <div className="amount-select">
            {['25', '50', '75', '100'].map((percentAmount) => (
              <div
                key={percentAmount}
                onClick={() => handlePercentAmountClick(percentAmount)}
              >
                {percentAmount}
                %
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="control">
          <div
            className="button rewards-button"
            onClick={claim}
          >
            Claim
            {' '}
            {userClaimableTokens.div(new BigNumber(KKO_DECIMALS)).toString()}
            {' '}
            KKO rewards
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeForm;
