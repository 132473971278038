import BigNumber from 'bignumber.js';
import React, {
  useState,
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';

export interface BalancesContextType {
  userKkoBalance: string
  setUserKkoBalance: Dispatch<SetStateAction<string>>
  userStakedBalance: BigNumber
  setUserStakedBalance: Dispatch<SetStateAction<BigNumber>>
  userClaimableTokens: BigNumber
  setUserClaimableTokens: Dispatch<SetStateAction<BigNumber>>
}

const BalancesContext = createContext<BalancesContextType>({
  userKkoBalance: '0',
  setUserKkoBalance: () => null,
  userStakedBalance: new BigNumber(0),
  setUserStakedBalance: () => null,
  userClaimableTokens: new BigNumber(0),
  setUserClaimableTokens: () => null,
});

export const BalancesProvider = ({ children }: { children: React.ReactElement }): React.ReactElement => {
  const [userKkoBalance, setUserKkoBalance] = useState('0');
  const [userStakedBalance, setUserStakedBalance] = useState(new BigNumber(0));
  const [userClaimableTokens, setUserClaimableTokens] = useState(new BigNumber(0));

  return (
    <BalancesContext.Provider value={{
      userKkoBalance,
      setUserKkoBalance,
      userStakedBalance,
      setUserStakedBalance,
      userClaimableTokens,
      setUserClaimableTokens,
    }}
    >
      {children}
    </BalancesContext.Provider>
  );
};

export const useBalances = (): Partial<BalancesContextType> => useContext(BalancesContext);
